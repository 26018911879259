import { IdentityProvider } from '@core/entities/auth'
import { createMachine } from 'xstate'

export type LoginMachineServices = {
  goToSingleSignOn: { data: any }
  submitDraft: { data: any }
  validateEmail: { data: IdentityProvider | undefined }
  login: { data: any }
}

export type LoginMachineEvents =
  | { type: 'EMAIL_CHANGED' }
  | { type: 'NEXT' }
  | { type: 'FORM_SUBMITTED'; data: { email: string; password: string } }

export const loginMachine = createMachine({
  predictableActionArguments: true,
  id: 'login',
  initial: 'email',
  tsTypes: {} as import('./LoginMachine.typegen').Typegen0,
  schema: {
    services: {} as LoginMachineServices,
    events: {} as LoginMachineEvents,
  },
  states: {
    email: {
      on: { NEXT: 'validatingEmail' },
    },
    validatingEmail: {
      invoke: {
        src: 'validateEmail',
        onDone: [
          {
            cond: 'isSingleSignOnAccount',
            target: 'singleSignOn',
          },
          { cond: 'emailValid', target: 'password' },
          { cond: 'emailInvalid', target: 'email' },
        ],
        onError: {
          target: 'email',
        },
      },
    },
    singleSignOn: {
      invoke: { src: 'goToSingleSignOn' },
      on: {
        EMAIL_CHANGED: {
          target: 'email',
        },
      },
    },
    password: {
      on: {
        EMAIL_CHANGED: {
          target: 'email',
        },
        FORM_SUBMITTED: {
          target: 'loggingIn',
        },
      },
    },
    loggingIn: {
      invoke: {
        src: 'login',
        onError: { target: 'password' },
        onDone: { target: 'success' },
      },
    },
    success: {},
  },
})
