import React from 'react'
import Login from '@modules/auth/Login/Login'
import { AuthPageLayout } from '@elements'

const HomePage = () => {
  return <Login />
}

HomePage.getLayout = ({ router, page }) => {
  return <AuthPageLayout page={page} />
}

export default HomePage
