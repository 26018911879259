import { localizedStrings } from '@core/strings'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { PageUrls } from '@core/page-urls'
import * as yup from 'yup'
import { Flex, Text } from '@fundamentals'
import { useModal } from '@common/GlobalModal'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { TextInput, Button, Card } from '@common/material'
import { useGetIdentityProvider } from '@core/react-query/features/auth'
import { useMachine } from '@xstate/react'
import { loginMachine } from '@modules/auth/Login/LoginMachine'
import { OpenMobilePrompt } from '@modules/open-mobile'
import { useGetProfile } from '@core/react-query/features/profile/hooks/useGetProfile'
import { loginRedirect } from 'components/loginRedirect'
import BackdropLoader from '@common/fundamentals/BackdropLoader'
import { useCognitoLogin } from '@core/react-query/features/auth/hooks/useCognitoLogin'
import { useToast } from '@core/toast'
import { useFederatedLoginExternalUri } from '@core/providers/useFederatedLoginExternalUri'

type FormData = {
  email: string
  password: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .email(localizedStrings.emailMustBeValid)
    .required(localizedStrings.emailIsRequired),
  password: yup.string().required(localizedStrings.passwordRequired),
})

const Login = () => {
  const router = useRouter()
  const toast = useToast()
  const { data: profile, refetch: refetchProfile } = useGetProfile({
    onError: (e) => toast.error(e),
  })
  const cognitoLoginMutation = useCognitoLogin()
  const { handleSubmit, control, formState, watch, trigger } =
    useForm<FormData>({
      resolver: yupResolver(schema),
      mode: 'onChange',
    })
  const email = watch('email')

  const getIdentityProvider = useGetIdentityProvider(
    { email: email },
    {
      // We manually fetch this when the next button is pressed
      enabled: false,
      retry: false,
    },
  )

  useEffect(() => {
    if (profile) {
      loginRedirect({ profile })
    }
  }, [profile])

  const { federatedLoginExternalUri } = useFederatedLoginExternalUri()

  const [state, send] = useMachine(loginMachine, {
    services: {
      validateEmail: async () => {
        const valid = await trigger('email')
        if (valid) {
          const response = await getIdentityProvider.refetch()
          if (response.error) {
            toast.error(response.error)
            throw response.error
          }
          return response.data
        }
      },
      goToSingleSignOn: async (context, event) => {
        showAlert({
          title: 'Single Sign-on Login',
          text: localizedStrings.ssoRedirectMessage,
          onDismiss: () => {
            router.push(
              federatedLoginExternalUri(
                event?.data?.identityProvider,
                PageUrls.federatedLoginRedirect(),
              ),
            )
          },
        })
      },
      login: async (context, event) => {
        try {
          await cognitoLoginMutation.mutateAsync({
            username: event.data.email,
            password: event.data.password,
          })
        } catch (e) {
          toast.error(e?.message)
          throw e
        }
      },
    },
    guards: {
      emailValid: () => {
        return !formState.errors.email
      },
      emailInvalid: () => {
        return !!formState.errors.email
      },
      isSingleSignOnAccount: (context, event) => {
        return !!event?.data?.identityProvider
      },
    },
  })

  const onSubmit = handleSubmit((data) => {
    send({ type: 'FORM_SUBMITTED', data: data })
  })

  const { showAlert } = useModal()
  const nextButtonVisible =
    state.matches('email') ||
    state.matches('validatingEmail') ||
    state.matches('singleSignOn')

  return (
    <>
      <OpenMobilePrompt />
      <Card data-test={'login'} sx={{ p: 3 }} title={localizedStrings.login}>
        <form onSubmit={onSubmit}>
          <Flex justifyContent={'center'} flexDirection={'column'}>
            <Text mb={2} variant={'h6'}>
              {localizedStrings.login}
            </Text>
            <Controller
              name='email'
              control={control}
              defaultValue=''
              render={({
                field: { onChange, ...restField },
                fieldState: { error },
              }) => (
                <TextInput
                  label='Email'
                  error={!!error}
                  helperText={error?.message || ' '}
                  onChange={(event) => {
                    onChange(event)
                    send({ type: 'EMAIL_CHANGED' })
                  }}
                  {...restField}
                />
              )}
            />
            {(state.matches('password') ||
              state.matches('loggingIn') ||
              state.matches('success')) && (
              <Controller
                name='password'
                control={control}
                defaultValue=''
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    sx={{ mt: 2 }}
                    type='password'
                    label='Password'
                    error={!!error}
                    helperText={error?.message || ' '}
                    {...field}
                  />
                )}
              />
            )}
            <Flex justifyContent={'flex-end'}>
              <Button
                data-test={'forgotPasswordButton'}
                onClick={() => router.push(PageUrls.forgotPassword({ email }))}
              >
                {localizedStrings.forgotPassword}
              </Button>
            </Flex>
            {nextButtonVisible && (
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  send({ type: 'NEXT' })
                }}
                data-test={'nextBtn'}
                disabled={!!formState.errors.email}
              >
                {localizedStrings.next}
              </Button>
            )}
            {(state.matches('password') ||
              state.matches('loggingIn') ||
              state.matches('success')) && (
              <Button
                variant='contained'
                type='submit'
                color='primary'
                data-test='jsLogin'
                disabled={!formState.isValid}
              >
                {localizedStrings.login}
              </Button>
            )}
          </Flex>
        </form>
      </Card>
      <BackdropLoader open={state.matches('loggingIn')} />
    </>
  )
}

export default Login
