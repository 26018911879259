import React, { useEffect } from 'react'
import { OpenMobileAppModal } from './OpenMobileAppModal'
import { useModal } from '@common/GlobalModal'
import { isMobile } from 'react-device-detect'
export const OpenMobilePrompt: React.FC<{}> = () => {
  const { showModal } = useModal()
  useEffect(() => {
    if (isMobile) {
      showModal(<OpenMobileAppModal />)
    }
  }, [isMobile])
  return null
}
