import React from 'react'
import {
  Alert,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@common/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Flex } from '@fundamentals'
import { useModal } from '@common/GlobalModal'
import { localizedStrings } from '@core/strings'
import { Android, Apple, Laptop } from '@mui/icons-material'
import Project from '@core/project'
import { ModalDialogContainer } from '@common/modal'

type OpenMobileAppModalProps = {
  onDismiss?: () => void
}
export const OpenMobileAppModal: React.FC<OpenMobileAppModalProps> = ({
  onDismiss: onDismissProp,
}) => {
  const { hideModal } = useModal()
  const onDismiss = () => {
    hideModal()
    onDismissProp && onDismissProp()
  }
  return (
    <ModalDialogContainer>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        {localizedStrings.openMobileApp}
        <IconButton aria-label='close-modal' onClick={onDismiss}>
          <ClearIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Flex alignItems={'center'} flexDirection={'column'}>
          <Alert severity={'info'} sx={{ mb: 3 }}>
            {localizedStrings.weSeeYoureOnMobileMessage}
          </Alert>
          <Button
            sx={{ mb: 3, width: '200px' }}
            startIcon={<Apple />}
            variant={'outlined'}
            onClick={() => {
              window.open(Project.appleAppStoreUrl, '_blank')
            }}
          >
            {localizedStrings.ios}
          </Button>
          <Button
            sx={{ mb: 3, width: '200px' }}
            startIcon={<Android />}
            variant={'outlined'}
            onClick={() => {
              window.open(Project.googlePlayStoreUrl, '_blank')
            }}
          >
            {localizedStrings.android}
          </Button>
          <Button
            sx={{ mb: 3, width: '200px' }}
            startIcon={<Laptop />}
            variant={'outlined'}
            onClick={onDismiss}
          >
            {localizedStrings.continueOnWeb}
          </Button>
        </Flex>
      </DialogContent>
    </ModalDialogContainer>
  )
}
