type UseFederatedLoginExternalUriType = {
  federatedLoginExternalUri: (
    identityProvider: string,
    redirectUri: string,
  ) => string
}

const useFederatedLoginExternalUri = (): UseFederatedLoginExternalUriType => {
  const cognitoClientId = API.isMobile()
    ? Project.cognitoMobile.userPoolWebClientId
    : Project.cognito.userPoolWebClientId

  const federatedLoginExternalUri = (
    identityProvider: string,
    redirectUri: string,
  ) =>
    `https://siteassist.auth.eu-west-2.amazoncognito.com/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectUri}&response_type=CODE&client_id=${cognitoClientId}&scope=email openid`

  return {
    federatedLoginExternalUri,
  }
}

export { useFederatedLoginExternalUri }
